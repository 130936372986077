/* eslint-disable max-len */
import MedicalContentService from '../../services/MedicalContentService';

export const fetchDirectionsById = async (id) => {
  const medicalContentService = new MedicalContentService();
  const response = await medicalContentService.getCommonDirectionsById(id);
  if (response.result) {
    return response.result.data;
  }
  return null;
};

export const extractUniqueFrequenciesFromDirectionData = (directions) => {
  /* Takes an array of directions as input, extracts the unique frequency objects based on
  their id property, and returns an object containing the unique frequencies as selection options.
  Includes prn data in the frequency objects.
  */
  const uniqueFrequencies = [
    ...new Map(directions.map((
      direction,
    ) => [direction.frequency.id, { ...direction.frequency, prn: direction.prn }])).values(),
  ];

  return { uniqueFrequencies };
};

const medicationBrandNamePrefix = [
  'apo', 'teva', 'sandoz', 'ratio', 'mylan', 'act',
];

// Extracts the prefix from the user-typed input (e.g., 'Teva-' from 'Teva-Acetaminophen')
export const extractPrefix = (inputValue) => {
  const lowerInputValue = inputValue.toLowerCase();

  // Loop through known prefixes to find and return the matching prefix
  const foundPrefix = medicationBrandNamePrefix.find((prefix) => lowerInputValue.startsWith(prefix));
  if (foundPrefix) {
    const spaceOrDashAfterPrefix = [' ', '-'].includes(lowerInputValue.at(foundPrefix.length));
    const finalIndex = spaceOrDashAfterPrefix ? foundPrefix.length + 1 : foundPrefix.length;
    return { prefix: inputValue.slice(0, finalIndex) || '', cleanedName: inputValue.slice(finalIndex) };
  }
  return { prefix: '', cleanedName: inputValue };
};
