import React, { useEffect } from 'react';
import { Grid, useMediaQuery } from '@mui/material';

function FhxError() {
  const isSmallScreen = useMediaQuery('(max-width: 500px)');

  useEffect(() => {
    if (window.top !== window.self) {
      window.parent.postMessage('IntakeNotFound', '*');
    }
  }, []);

  return (
    <Grid
      item
      sx={{
        position: 'fixed',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundImage: isSmallScreen
          ? 'url(assets/images/firstHx_notfound_mobile.png)'
          : 'url(assets/images/firstHx_notfound.png)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
}

export default FhxError;
