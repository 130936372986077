import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRollbar } from '@rollbar/react';
import {
  Box,
  Button,
  Container,
  CircularProgress,
  Grid,
} from '@mui/material';
import { createTheme } from '@mui/material/styles';
import FhxLocalization from '../content/FhxLocalization';
import FhxPtInitiatedIntakeForm from '../content/FhxPtInitiatedIntakeForm';
import IntakeServices from '../services/IntakeServices';
import Toast, { useToast } from '../common/useToast';
import FhxError from '../content/FhxError';

const services = new IntakeServices();

function PtInitiatedIntakePage() {
  const rollbar = useRollbar();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const siteId = searchParams.get('siteId');
  if (!siteId) return <FhxError />;
  const [recaptchaLanguage, setRecaptchaLanguage] = useState('en');
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [captchaEnabled, setCaptchaEnabled] = useState(false);
  const [useAge, setUseAge] = useState(false);
  const { toastState, toastSetErrorNotification, toastOnClose } = useToast();

  const availableLanguages = {
    en: 'English',
    fr_CA: 'Français',
    ar: 'العربية',
  };

  const getCaptchaLangCode = (languageCode) => {
    const langMap = {
      en: 'en',
      fr_CA: 'fr-CA',
      ar: 'ar',
    };
    return langMap[languageCode] || languageCode;
  };

  const changeLanguage = (lang) => {
    i18n.changeLanguage(lang);
    setRecaptchaLanguage(getCaptchaLangCode(lang));
  };

  useEffect(() => {
    setLoading(true);
    services
      .getPatientInitiatedIntakeSettings(siteId)
      .then((response) => {
        if (response && response.result.data) {
          const { languageOptions, skipCaptcha, qrUseAgeInsteadOfDob } = response.result.data;
          let languageCodes = [];
          if (languageOptions) {
            languageCodes = languageOptions
              .split(',')
              .map((lang) => {
                switch (lang.trim().toLowerCase()) {
                  case 'english':
                    return 'en';
                  case 'arabic':
                    return 'ar';
                  case 'french-canadian':
                    return 'fr_CA';
                  default:
                    return lang;
                }
              })
              .filter((lang) => Object.keys(availableLanguages).includes(lang));
          }
          if (languageCodes.length === 0) {
            languageCodes.push('en');
          }
          setLanguages(languageCodes);
          setCaptchaEnabled(skipCaptcha !== 'true');
          setUseAge(qrUseAgeInsteadOfDob === 'true');
        } else {
          throw new Error('Invalid response structure');
        }
      })
      .catch((error) => {
        rollbar.error('Failed to fetch site settings', error);
        toastSetErrorNotification(t('errMsgToContactSupport'));
      })
      .finally(() => {
        setLoading(false);
      });
  }, [siteId]);

  useEffect(() => {
    if (languages.length > 0) {
      changeLanguage(languages[0]);
    }
  }, [languages]);

  const handlePiiSubmit = ({ pii, captchaToken }) => {
    const piiWithPtSelectedLang = {
      ...pii,
      patientLanguage: i18n.language,
    };
    setLoading(true);
    services
      .createPatientInitiatedIntake(siteId, piiWithPtSelectedLang, captchaToken)
      .then((response) => {
        global.mkey = response?.result?.data.mkey;
        if (global.mkey) {
          navigate(`?mkey=${global.mkey}`, { replace: true });
        }
      })
      .catch((error) => {
        rollbar.error('Failed to submit PII data', error);
        toastSetErrorNotification(t('errMsgToContactSupport'));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <FhxLocalization theme={createTheme({})} localization={i18n}>
      <Container maxWidth="md" sx={{ paddingTop: 3, paddingBottom: 2 }}>
        {!loading ? (
          <>
            <Box display="flex" justifyContent="center" mb={2}>
              {languages.map((lang) => (
                <Button
                  key={lang}
                  onClick={() => changeLanguage(lang)}
                  variant={i18n.language === lang ? 'outlined' : 'primary'}
                >
                  {availableLanguages[lang]}
                </Button>
              ))}
            </Box>
            <FhxPtInitiatedIntakeForm
              onSubmit={handlePiiSubmit}
              skipCaptcha={!captchaEnabled}
              recaptchaLanguage={recaptchaLanguage}
              qrUseAgeInsteadOfDob={useAge}
              toastSetErrorNotification={toastSetErrorNotification}
            />
          </>
        ) : (
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: '100vh' }}>
            <CircularProgress />
          </Grid>
        )}
        <Toast
          open={toastState.open}
          severity={toastState.severity}
          text={toastState.text}
          onClose={toastOnClose}
          autoHideDuration={6000}
        />
      </Container>
    </FhxLocalization>
  );
}

export default PtInitiatedIntakePage;
