import { darken } from 'polished';

export default function customizeTheme(theme) {
  const baseActiveButtonColor = theme.activeButtonColor || '#1f64af';
  const hoverColor = theme.activeButtonHoverColor || darken(0.1, baseActiveButtonColor);
  const primaryTheme = theme.primaryTheme || '#1976d2';
  const fullRoundedBorderRadius = '999px';
  const unselectedButtonColor = theme.unselectedButtonColor || '#FFFFFF';

  return {
    radioButtonIcon: 'checkmark',
    typography: {
      fontFamily: theme.fontTheme || 'Poppins,Nunito,Roboto,Helvetica,Arial,sans-serif',
      h1: {
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: '1.4',
      },
      h2: {
        fontWeight: 500,
        fontSize: '1.125rem',
        lineHeight: '1.4',
      },
      body1: {
        fontWeight: 500,
        fontSize: '1rem',
        lineHeight: '1.5',
      },
    },
    palette: {
      primary: {
        main: primaryTheme,
      },
      // Since the color below are very safe to match all the themes,
      // we can use them as hard-coded values
      border: {
        main: '#a1a1a1',
      },
      background: {
        main: '#d0d0d0',
        secondary: '#e6e6e605',
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: 'capitalize',
            '&.MuiButton-containedPrimary:not(.Mui-disabled)': {
              backgroundColor: baseActiveButtonColor,
              color: 'white',
              border: `1px solid ${baseActiveButtonColor}`,
              padding: '8px 32px',
              borderRadius: fullRoundedBorderRadius,
              whiteSpace: 'nowrap',
              ...(theme.activeButtonColor ? {} : {
                '&:active': {
                  backgroundColor: '#1F64B0 !important',
                  color: 'white',
                },
                '&.Mui-selected': {
                  backgroundColor: '#1F64B0 !important',
                  color: 'white',
                },
              }),
              ...(theme.activeButtonColor && !theme.activeButtonHoverColor ? {} : {
                '&:hover': {
                  backgroundColor: hoverColor,
                  color: 'white',
                },
              }),
            },
            '&.MuiButton-containedPrimary.Mui-disabled': {
              backgroundColor: theme.inactiveButtonColor,
              color: '#5c5c5c',
              padding: '8px 32px',
              borderRadius: fullRoundedBorderRadius,
            },
            '&.MuiButton-outlinedPrimary:not(.Mui-disabled)': {
              textTransform: 'capitalize',
              color: primaryTheme,
              border: `1px solid ${primaryTheme}`,
              borderRadius: fullRoundedBorderRadius,
              padding: '8px 32px',
              minWidth: '36px',
              width: 'auto',
              whiteSpace: 'nowrap',
              justifyContent: 'center',
              ...(theme.activeButtonColor && !theme.activeButtonHoverColor ? {} : {
                '&:hover': {
                  backgroundColor: theme.activeButtonHoverColor || '#1f64af',
                  border: `1px solid ${theme.activeButtonHoverColor}` || '#1f64af',
                  color: '#fff',
                },
              }),
            },
            '&.MuiButton-outlinedSecondary:not(.Mui-disabled)': {
              textTransform: 'capitalize',
              color: theme.activeButtonColor || 'black',
              border: `1px solid ${theme.activeButtonColor || '#595858'}`,
              borderRadius: fullRoundedBorderRadius,
              padding: '8px 32px',
              minWidth: 'auto',
              justifyContent: 'center',
              ...(theme.activeButtonColor && !theme.activeButtonHoverColor ? {} : {
                '&:hover': {
                  backgroundColor: theme.activeButtonHoverColor || '#1f64af',
                  border: `1px solid ${theme.activeButtonHoverColor}` || '#1f64af',
                  color: '#fff',
                },
              }),
            },
            '&.MuiButton-outlinedSecondary.Mui-disabled': {
              backgroundColor: theme.inactiveButtonColor,
              color: 'white',
              minWidth: 'auto',
              borderRadius: '9999px',
              padding: '8px 32px',
              justifyContent: 'center',
            },
            '&:focus': {
              outline: '2px solid rgba(119, 119, 119, 1)',
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none',
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            color: 'black',
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            textTransform: 'none',
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: unselectedButtonColor,
            color: 'black',
            margin: ' 0px 0px 12px 0px !important',
            padding: '5px 10px',
            minHeight: '40px',
            borderRadius: '4px !important',
            border: `1px solid ${darken(0.3686, unselectedButtonColor)} !important`,
            '&.Mui-selected': {
              backgroundColor: theme.activeButtonColor || '#1F64B0 !important',
              color: 'white',
              border: `1px solid ${theme.activeButtonColor || '#1F64B0'} !important`,
            },
            '&:hover': {
              backgroundColor: hoverColor,
              color: 'white',
              border: `1px solid ${hoverColor} !important`,
            },
            '&:hover.Mui-selected': {
              backgroundColor: hoverColor,
              color: 'white',
              border: `1px solid ${hoverColor} !important`,
            },
            '&:focus': {
              outline: '2px solid rgba(119, 119, 119, 1)',
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none',
            },
          },
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            margin: '0px auto',
          },
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            color: theme.activeButtonColor || '#1F64B0',
            padding: '0px',
            margin: '6px',
            borderRadius: fullRoundedBorderRadius,
            border: '0.5px transparent',
            '&:hover': {
              backgroundColor: hoverColor,
              color: 'white',
            },
            '&:focus': {
              outline: '2px solid rgba(119, 119, 119, 1)',
            },
            '&:focus:not(:focus-visible)': {
              outline: 'none',
            },
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          root: {
            borderRadius: fullRoundedBorderRadius,
            padding: '8px 32px',
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          root: {
            display: 'flex',
            flexDirection: 'row-reverse',
            alignItems: 'center',
            justifyContent: 'space-between',
            margin: '0 auto',
            padding: '6px 16px',
            border: `1px solid ${darken(0.3686, unselectedButtonColor)}`,
            borderRadius: '4px',
            minHeight: '42px',
            width: '100%',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            transform: 'scale(0.75)',
            padding: '0px',
            '&.Mui-checked': {
              color: 'white',
            },
            '&.Mui-focusVisible': {
              outline: '2px solid rgba(119, 119, 119, 1)',
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            transform: 'scale(0.75)',
            padding: '0px',
            '&.Mui-checked': {
              color: 'white',
            },
            '&.Mui-focusVisible': {
              outline: '2px solid rgba(119, 119, 119, 1)',
              borderRadius: '2px',
            },
          },
        },
      },
    },
  };
}
