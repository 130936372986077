import React, { useState } from 'react';
import {
  Grid,
  Typography,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ReCAPTCHA from 'react-google-recaptcha';
import { format } from 'date-fns';
import { Controls } from '../common/Controls';
import { MIN_TEXT_FIELD_WIDTH } from '../common/Input';
import { FhxNavButtons } from '../common/FhxContentComponents';
import DateField from '../common/DateField';

const { REACT_APP_GOOGLE_CAPTCHA_SITE_KEY } = process.env;

function FhxPtInitiatedIntakeForm({
  onSubmit,
  skipCaptcha,
  recaptchaLanguage,
  qrUseAgeInsteadOfDob,
  toastSetErrorNotification,
}) {
  const { t } = useTranslation();
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: null,
    age: '',
    captchaToken: null,
  });
  const [isDobValid, setIsDobValid] = useState(false);
  const [ageError, setAgeError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleVerificationSuccess = (token) => {
    setFormState((prevState) => ({ ...prevState, captchaToken: token }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleDobChange = (date) => {
    setFormState((prevState) => ({ ...prevState, dateOfBirth: date }));
  };

  const handleAgeChange = (e) => {
    const { value } = e.target;
    const age = Number(value);
    setFormState((prevState) => ({ ...prevState, age: value }));

    if (value && (Number.isNaN(age) || age < 0 || age > 120)) {
      setAgeError(t('ageField.invalidAge'));
    } else {
      setAgeError('');
    }
  };

  const handleSubmit = () => {
    setIsLoading(true);
    try {
      const {
        firstName, lastName, dateOfBirth, age, captchaToken,
      } = formState;
      if (!firstName || !lastName || !(skipCaptcha || captchaToken)) {
        toastSetErrorNotification(t('pii.completeAllFields'));
        return;
      }
      if (!qrUseAgeInsteadOfDob) {
        if (!dateOfBirth) {
          toastSetErrorNotification(t('pii.completeAllFields'));
          return;
        }
        if (!isDobValid) {
          toastSetErrorNotification(t('pii.invalidDob'));
          return;
        }
        const formattedDob = format(dateOfBirth, 'yyyy-MM-dd');

        onSubmit({
          pii: {
            patientId: `${firstName}-${lastName}-${formattedDob}`,
            patientFirstName: firstName,
            patientLastName: lastName,
            dateOfBirth: formattedDob,
          },
          captchaToken,
        });
      } else {
        if (!age || ageError) {
          toastSetErrorNotification(t('pii.completeAllFields'));
          return;
        }
        onSubmit({
          pii: {
            patientId: `${firstName}-${lastName}-${age}`,
            patientFirstName: firstName,
            patientLastName: lastName,
            ageInYears: age,
          },
          captchaToken,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const continueButtonAllowed = () => {
    if (skipCaptcha) {
      return qrUseAgeInsteadOfDob ? !!formState.age && !ageError : formState.dateOfBirth !== null;
    }
    return formState.captchaToken !== null;
  };

  return (
    <Grid container spacing={2} justifyContent="center" sx={{ mt: 1 }}>
      <Grid item xs={12} mt={3} md={8} minWidth={MIN_TEXT_FIELD_WIDTH}>
        <Typography variant="subtitle2" gutterBottom>
          {t('pii.enterYourInformation')}
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} minWidth={MIN_TEXT_FIELD_WIDTH}>
        <Controls.Input
          required
          fullWidth
          id="firstName"
          label={t('pii.firstName')}
          name="firstName"
          value={formState.firstName}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} md={8} minWidth={MIN_TEXT_FIELD_WIDTH}>
        <Controls.Input
          required
          fullWidth
          id="lastName"
          label={t('pii.lastName')}
          name="lastName"
          value={formState.lastName}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} md={8} minWidth={MIN_TEXT_FIELD_WIDTH}>
        {qrUseAgeInsteadOfDob ? (
          <TextField
            id="outlined-number"
            label={t('ageField.label')}
            value={formState.age}
            onChange={handleAgeChange}
            inputProps={{ type: 'number', inputMode: 'decimal', 'data-testid': 'numberInput' }}
            error={!!ageError}
            helperText={ageError}
            fullWidth
          />
        ) : (
          <DateField
            label={t('pii.dateOfBirth')}
            value={formState.dateOfBirth}
            setValue={handleDobChange}
            disableFuture
            required
            fullWidth
            setIsValid={setIsDobValid}
          />
        )}
      </Grid>
      {!skipCaptcha && (
        <Grid item xs={12} md={8} minWidth={MIN_TEXT_FIELD_WIDTH} style={{ display: 'flex', justifyContent: 'center' }}>
          <ReCAPTCHA
            key={recaptchaLanguage}
            sitekey={REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
            onChange={handleVerificationSuccess}
            hl={recaptchaLanguage}
          />
        </Grid>
      )}
      <Grid item xs={12} md={8} minWidth={MIN_TEXT_FIELD_WIDTH}>
        <FhxNavButtons
          backAllowed={false}
          continueAllowed={continueButtonAllowed()}
          continueButtonCallback={handleSubmit}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  );
}

FhxPtInitiatedIntakeForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  skipCaptcha: PropTypes.bool.isRequired,
  recaptchaLanguage: PropTypes.string.isRequired,
  qrUseAgeInsteadOfDob: PropTypes.bool.isRequired,
  toastSetErrorNotification: PropTypes.func.isRequired,
};

export default FhxPtInitiatedIntakeForm;
